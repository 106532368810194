import { GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import AWS from "aws-sdk";
import { randomUUID } from "crypto";

const s3 = new S3Client({
    region: 'ap-northeast-1',
    credentials: {
        secretAccessKey: 'tAaM6ABWGB1ev7OeeqLJyme0zHekLLkKEr07ICdm',
        accessKeyId: 'AKIA4SA73J7HF55WY2KT'
    }
})

export const getItem = async (itemName: string) => {
    const command = new GetObjectCommand({
        Bucket: 'co-avm-picture',
        Key: itemName,
    })

    const response = await s3.send(command)
    const data = response.Body?.transformToString('base64')

    return data
}

export const putItem = async (fileName: string, body: any) => {
    const command = new PutObjectCommand({
        Bucket: 'co-avm-picture',
        Key: fileName,
        Body: body
    })

    const response = await s3.send(command)
    console.log(response)

    return response
}

