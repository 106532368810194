
import * as AWS from 'aws-sdk'
import { GetItemOutput, PutItemInputAttributeMap, StringAttributeValue } from 'aws-sdk/clients/dynamodb'

const docClient = new AWS.DynamoDB.DocumentClient(
    {
        region: 'ap-northeast-1',
        credentials: {
            secretAccessKey: 'tAaM6ABWGB1ev7OeeqLJyme0zHekLLkKEr07ICdm',
            accessKeyId: 'AKIA4SA73J7HF55WY2KT'
        }
    }
)

export const updateSequence = (tableName: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const inputParams: AWS.DynamoDB.GetItemInput = {
            TableName: 'sequence',
            Key: {
                tableName: tableName,
            }
        }
        docClient.get(inputParams, (err, data) => {
            if (err) reject(err)
            else {
                let newSeq = data.Item!.seq + 1
                const param = {
                    TableName: 'sequence',
                    Item: {
                        tableName: tableName,
                        seq: newSeq,
                    }
                }
                docClient.put(param, (err, data) => {
                    // console.log(err)
                    // console.log(data)
                })
                resolve(data.Item)
            }
        })
    })

}

export const putAvmInformation =  (data: any) => {
    return new Promise(async (resolve, reject) => {
        let item = await updateSequence('avm-information')
        let seq = item.seq
        console.log(seq)

        seq = seq + 1

        const idData = { id: seq }

        const params: AWS.DynamoDB.PutItemInput = {
            TableName: 'avm-information',
            Item: { ...data, ...idData }
        }

        docClient.put(params, (err, data) => {
            if (err) reject(err)
            else resolve(data)
        })
    })
}

export const getAvmInformation = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        docClient.scan({ TableName: 'avm-information' }, (err, data) => {
            if (err) reject(err)
            else {
                // console.log(data.Items)
                resolve(data.Items)
            }
        })
    })

}

export const updateAvmInformation = (data: any) => {
    return new Promise(async (resolve, reject) => {

        const params: AWS.DynamoDB.PutItemInput = {
            TableName: 'avm-information',
            Item: data
        }

        docClient.put(params, (err, data) => {
            if (err) reject(err)
            else resolve(data)
        })
    })
}
