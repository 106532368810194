import { useState } from "react"
import { RareDrinkGenre } from "../interface"

interface RareDrinkList {
    [key: string]: RareDrinkGenre 
}

export const RARE_DRINK_LIST: RareDrinkList = {
    // { label: 'コカ・コーラ ', value: '' },
    cocaCola: {
        label: 'コカ・コーラ',
        drinkList: [
            { label: 'コカ・コーラ ゼロカフェイン', value: 'zeroCaffeine' },
            { label: 'コカ・コーラ プラス', value: 'plus' },
            { label: 'コカ・コーラ ゼロシュガーレモン', value: 'zeroSugerLemon' }
        ],
    },
    // { label: 'い・ろ・は・す ', value: '' },
    irohasu: {
        label: 'い・ろ・は・す',
        drinkList: [
            { label: 'い・ろ・は・す 天然水にれもん', value: 'tennensuiNiLemon' },
            { label: 'い・ろ・は・す みかん', value: 'mikan' },
            { label: 'い・ろ・は・す りんご', value: 'ringo' },
            { label: 'い・ろ・は・す なし', value: 'nashi' },
            { label: 'い・ろ・は・す ハスカップ', value: 'huscap' },
            { label: 'い・ろ・は・す シャインマスカット', value: 'shineMuscat' },
            { label: 'い・ろ・は・す 巨峰', value: 'kyoho' },
            { label: 'い・ろ・は・す スパークリングれもん', value: 'sparklingLemon' },
            { label: 'い・ろ・は・す 塩とれもん', value: 'shioToLemon' },
        ],
    },
    // { label: '綾鷹 ', value: '' },
    ayataka: {
        label: '綾鷹',
        drinkList: [
            { label: '綾鷹 黒豆ほうじ茶', value: 'blackBeans' },
            { label: '綾鷹 特選茶', value: "tokusencha" },
            { label: '綾鷹 ほうじ茶ラテ', value: 'hojichaLatte' },
            { label: '綾鷹 急須珈琲 ラテ', value: 'kyusuCoffeeLatte' },
            { label: '綾鷹 急須珈琲 ブラック', value: 'kyusuCoffeeBlack' },
        ],
    },
    // { label: 'アクエリアス ', value: ''},
    aquarius: {
        label: 'アクエリアス',
        drinkList: [
            { label: 'アクエリアス NEWATER', value: 'newater'},
            { label: 'アクエリアス ゼロ', value: 'zero'},
            { label: 'アクエリアス 1日分のマルチビタミン', value: "multiVitaminn" },
            { label: 'アクエリアス 乳酸菌ウォーター', value: 'lacticAcidBacteria'},
            { label: 'アクエリアス 経口補水液', value: 'oralRehydrationSolution'},
            { label: 'アクエリアス スパークリング', value: "sparkling" },
        ],
    },
    // { label: 'ジョージア ', value: '' },
    georgia: {
        label: 'ジョージア',
        drinkList: [
            { label: 'ジョージア THE 微糖', value: 'theBito' },
            { label: 'ジョージア エメラルドマウンテンブレンド カフェオレ砂糖不使用', value: 'emeraldNonSuger' },
            { label: 'ジョージア ご褒美カフェオレ', value: 'gohoubiCafeAuLait' },
            { label: 'ジョージア テイスティ', value: 'tasty' },
            { label: 'ジョージア マックスコーヒー', value: 'maxCoffee' },
            { label: 'ジョージア サントスプレミアム 北海道限定', value: 'santosPremiumHokkaido' },
            { label: 'ジョージア デミタス', value: 'demitasse' },
            { label: 'ジョージア デミタス 微糖', value: 'demitasseSlightSuger' },
            { label: 'ジョージア ヨーロピアン', value: 'european' },
            { label: 'ジョージア ジャパンクラフトマン ホワイトモカ', value: 'japaCraWhiteMocha' },
            { label: 'ジョージア THE ゼロ', value: 'theZero' },
            // { label: 'ジョージア 贅沢クリーミーカフェ ゼロ', value: 'creamyZero' },
            { label: 'ジョージア クリアブレンド', value: 'clearBlend' },
            { label: 'ジョージア ビターショコラ ラテ', value: 'bitterChocolat' },
            { label: 'ジョージア キャラメルアフォガート ラテ', value: 'caramelAffogarto' },
            { label: 'ジョージア ピスタチオラテ', value: 'pistachio' },
            { label: 'ジョージア 喫茶店の炭火焙煎風 微糖', value: 'kissatenBito' },
            { label: 'ジョージア ザ・ブレンド', value: 'theBlend' },
            { label: 'ジョージア THE ラテ シャインマスカット', value: 'theShineMuscat' },
            { label: 'ジョージア ミルクコーヒー', value: 'milkCoffee' },
            { label: 'ジョージア バナナミルクコーヒー', value: 'bananaMilkCoffee' },
            { label: 'ジョージア ザ・プレミアム スペシャルエディション', value: 'thePremium'}
        ]
    },
    // { label: 'ファンタ ', value: ''},
    fanta: {
        label: 'ファンタ',
        drinkList: [
            { label: 'ファンタ オレンジ', value: 'orange'},
            { label: 'ファンタ レモン マルチビタミン1日分', value: 'lemonMultiVitaminn'},
            { label: 'ファンタ メロンソーダ', value: 'melonSoda'},
            { label: 'ファンタ ミステリースイーツ', value: 'mistery'},
            { label: 'ファンタ レモンプレミア ピンクグレープフルーツ', value: 'lemonPremierPinkGrapeFruit'},
        ]
    },
    sprite: {
        label: 'スプライト',
        drinkList: [
            { label: 'スプライト', value: 'sprite'},
        ]
    },
    // { label: 'カナダドライ ', value: ''},
    canadaDry: {
        label: 'カナダドライ',
        drinkList: [
            { label: 'カナダドライ ジンジャーエール', value: 'ginger'},
            { label: 'カナダドライ トニックウォーター', value: 'tonic'},
            { label: 'アイシー・スパーク from カナダドライ', value: 'icy'},
            { label: 'アイシー・スパーク from カナダドライ レモン', value: 'icyLemon'},
        ]
    },
    // { label: '爽健美茶', value: ''},
    sokenbicha: {
        label: '爽健美茶',
        drinkList: [
            { label: '爽健美茶', value: 'sokenbicha'},
            { label: '爽健美茶 健康素材の麦茶', value: 'healthyBarley'},
            { label: '爽健美温茶', value: 'bioncha'},
        ]
    },
    // { label: '紅茶花伝', value: ''},
    kochakaden: {
        label: '紅茶花伝',
        drinkList: [
            { label: '紅茶花伝 クラフティ― 贅沢しぼりレモンティー', value: 'lemonTea'},
            { label: '紅茶花伝 クラフティ― 贅沢しぼりオレンジティー', value: 'orangeTea'},
            { label: '紅茶花伝 ガーデンレモンティー', value: 'gardenLemonTea'},
            { label: '紅茶花伝 ガーデンシークヮーサーティー', value: 'gardenShekwashaTea'},
            { label: '紅茶花伝 ロイヤルレモンティー', value: 'royalLemonTea'},
        ]
    },
    karadasukoyakacha: {
        label: 'からだすこやか茶',
        drinkList: [
            { label: 'からだすこやか茶W', value: 'sukoyakachaW'},
        ]
    },
    karadaodayakacha: {
        label: 'からだおだやか茶',
        drinkList: [
            {label: 'からだおだやか茶W', value: 'odayakachaW'},
        ]
    },
    karadarefreshcha: {
        label: 'からだリフレッシュ茶',
        drinkList: [
            { label: 'からだリフレッシュ茶W', value: 'refreshchaW'},
        ]
    },
    karadameguricha: {
        label: 'からだ巡茶',
        drinkList: [
            { label: 'からだ巡茶（アドバンスではない）', value: 'meguricha'},
        ]
    },
    saryusaisai: {
        label: '茶流彩彩',
        drinkList: [
            { label: '茶流彩彩 さんぴん茶', value: 'sanpincha'},
            { label: '茶流彩彩 麦茶', value: 'mugicha'},
        ]
    },
    // { label: 'コスタコーヒー ', value: ''},
    costaCoffee: {
        label: 'コスタコーヒー',
        drinkList: [
            { label: 'コスタコーヒー プレミアムブラック', value: 'premiumBlack'},
            { label: 'コスタコーヒー エスプレッソ＆ミルク', value: 'espressoMilk'},
            { label: 'コスタコーヒー エスプレッソ＆ブラック', value: 'espressoBlack'},
            { label: 'コスタコーヒー 抹茶エスプレッソラテ', value: 'matchEspressoLatte'},
            { label: 'コスタコーヒー ハニーラテ', value: 'honeyLatte'},
            { label: 'コスタコーヒー ヘーゼルナッツラテ', value: 'hazelnutLatte'},
        ]
    },
    qoo: {
        label: 'Qoo',
        drinkList: [
            { label: 'ミニッツメイド Qoo オレンジ', value: 'orange'}
        ]
    },
    realGold: {
        label: 'リアルゴールド',
        drinkList: [
            { label: 'リアルゴールド オリジナル', value: 'original'},
            { label: 'リアルゴールド Y', value: 'Y'},
        ]
    },
    chillOut: {
        label: 'チルアウト',
        drinkList: [
            { label: 'チルアウト リラクゼーションドリンク ゼログラビティ', value: 'zeroGravity'},
        ]
    },
    bonAqua: {
        label: 'ボナクア',
        drinkList: [
            { label: 'ボナクア やさしい軟水', value: 'softWater'},
        ]
    },
    others: {
        label: 'その他',
        drinkList: [
            {label: 'アンバサ サワーホワイト', value: 'ambasaSour'},
            {label: 'Hi-C オレンジ', value: 'hi-cOrange'},
            {label: 'Hi-C アップル', value: 'hi-cApple'},
            {label: '他社製品', value: 'otherCompany'},
        ]
    }
}

export const CABINET = [
    { label: 'Coke ON非対応', value: 'notCokeOn'},
    { label: 'Coke ON対応', value: 'cokeOn'},
    { label: '大阪万博支援自販機', value: 'osakaExpo'},
    { label: 'ジョージア カフェ', value: 'georgiaCafe'}
]