import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
interface Props {
  status: "success" | "error";
  content: string;
  setShowSnackBar: Function;
}

const getSnackBarConfig = (status: Props["status"]) => {
  let config = {
    border: "",
    bg: "",
    icon: <span></span>,
  };
  if (status === "success") {
    config.border = "border-green-700";
    config.bg = "bg-green-50";
    config.icon = <FaCheckCircle className="text-green-700" size={24} />;
  }
  if (status === "error") {
    config.border = "border-red-600";
    config.bg = "bg-red-50";
    config.icon = <MdError className="text-red-600" size={24} />;
  }
  return config;
};

const SnackBar = (props: Props) => {
  const { status, content, setShowSnackBar } = props;

  const config = getSnackBarConfig(status);

  setTimeout(() => setShowSnackBar(false), 3000);

  return (
    <div
      className={`absolute left-1/2 -translate-x-1/2 top-16 z-50 w-[90%] max-w-[500px] min-w-[300px] flex rounded border-2 p-3 ${config.border} ${config.bg}`}
    >
      <div className="flex grow">
        <div className="mr-4 self-center">{config.icon}</div>
        <div className="grow">{content}</div>
      </div>
      <button className="ml-4" onClick={() => setShowSnackBar(false)}>
        <RxCross1 size={24} />
      </button>
    </div>
  );
};

export default SnackBar;
