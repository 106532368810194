import { MouseEventHandler } from "react"

interface Prop {
    titleCaption: string
    contentCaptionList?: string[]
    onClickCancelButton: MouseEventHandler<HTMLButtonElement>
    onClickOKButton: MouseEventHandler<HTMLButtonElement>
    cancelButtonCaption?: string
    okButtonCaption?: string

}
const Modal = (props: Prop) => {
    const {
        titleCaption,
        contentCaptionList,
        onClickCancelButton,
        onClickOKButton,
        cancelButtonCaption = 'キャンセル',
        okButtonCaption = 'OK'
    } = props

    return (
        <div className='absolute inset-0 m-auto w-[60%] max-w-[500px] min-w-[300px] h-[60%] max-h-[300px] bg-white text-center rounded-md shadow-md'>
            <div className='mt-12 mx-6 mb-6'>
                <h2 className='mb-6'>{titleCaption}</h2>
                <div className="text-base">
                    {contentCaptionList && contentCaptionList.map((sentence) => {
                        return <p>{sentence}</p>
                    })}
                </div>
            </div>
            <div className=' flex flex-col items-center justify-center mt-6'>
                <button
                    className='w-3/5 h-12 w-48 bg-slate-200 rounded-full border-slate-300 border-2 my-1 text-sm'
                    onClick={onClickCancelButton}>{cancelButtonCaption}</button>
                <button
                    className='w-3/5 h-12 w-48 bg-coke-red rounded-full border-rose-300 border-2 text-white my-1 text-sm'
                    onClick={onClickOKButton}>{okButtonCaption}</button>
            </div>
        </div>
    )
}

export default Modal