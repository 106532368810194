import React, {
  useRef,
  useEffect,
  useState,
  RefObject,
  ChangeEventHandler,
  ChangeEvent,
  useCallback,
} from "react";
import Map, {
  GeolocateControl,
  Layer,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  useMap,
} from "react-map-gl";
import "leaflet/dist/leaflet.css";
import mapboxgl, { LngLat, MapLayerMouseEvent } from "mapbox-gl";
import AddButton from "../container/AddButton";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {
  getAvmInformation,
  putAvmInformation,
  updateAvmInformation,
} from "../api/DynamDB";
import { getItem, putItem } from "../api/S3";
import Modal from "../container/Modal";
import { CABINET, RARE_DRINK_LIST } from "../const/Const";
import DrinkListAccordion from "../container/DrinkListAccordion";
import { StateList } from "../interface";
import ManagementNumber from "../container/ManagementNumber";
import ChevronUpIcon from "@heroicons/react/24/outline/ChevronUpIcon";
import { v4 as uuidv4 } from "uuid";
import { divIcon } from "leaflet";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import SnackBar from "../container/SnackBar";
import ProgressCircle from "../container/ProgressCircle";

interface prop {
  globalHeaderHeight: string;
}

const getMarkerColor = (cabinet: string) => {
  let color = "#f40000";

  if (cabinet === "notCokeOn") color = "#cccccc";
  if (cabinet === "cokeOn") color = "#f40000";
  if (cabinet === "osakaExpo") color = "#0068b7";
  if (cabinet === "georgiaCafe") color = "#945b04";

  console.log(cabinet, color);

  return color;
};

const BaseMap = (props: prop) => {
  const { globalHeaderHeight } = props;

  const [rareDrink, setRareDrink] = useState<{ [key: string]: string[] }>(
    Object.keys(RARE_DRINK_LIST).reduce(
      (obj, key) => Object.assign(obj, { [key]: [] }),
      {}
    )
  );

  const [avmData, setAvmData] = useState<any>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [addingMarker, setAddingMarker] = useState<LngLat>();
  const [showBackLayer, setShowBackLayer] = useState<boolean>(false);
  const [showRegiserThisPointModal, setShowRegiserThisPointModal] =
    useState(false);
  const [showRegisterMode, setShowRegisterMode] = useState<boolean>(false);
  const [placeOfAvm, setPlaceOfAvm] = useState<string>("");
  const [managementNumber, setManagementNumber] = useState<string>("");
  const [loadAvmInfoFlag, setLoadAvmInfoFlag] = useState<boolean>(true);
  const [showRegisterCancelConfirmModal, setShowRegisterCancelConfirmModal] =
    useState<boolean>(false);
  const [showEditCancelConfirmModal, setShowEditCancelConfirmModal] =
    useState<boolean>(false);
  const [showRegisterConfirmModal, setShowRegisterConfirmModal] =
    useState<boolean>(false);
  const [displayDetail, setDisplayDetail] = useState<number[]>([]);
  const [picture, setPicture] = useState<any>(null);
  const [avmInfo, setAvmInfo] = useState<any>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [cabinet, setCabinet] = useState("");
  const [showEditMode, setShowEditMode] = useState<boolean>(false);
  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarOption, setSnackBarOption] = useState<{
    status: "success" | "error";
    content: string;
  }>({
    status: "success",
    content: "",
  });
  const [remarks, setRemarks] = useState("");
  const [showProgressCircle, setShowProgressCircle] = useState(true);

  const placeOfAvmValidation = placeOfAvm.length == 0 || placeOfAvm.length > 60;
  const managementNumberValidation = managementNumber.length > 10;
  const cabinetValidation = cabinet === "";
  const remarksValidation = remarks.length > 200;
  const disableRegisterButtonFlag =
    placeOfAvmValidation ||
    managementNumberValidation ||
    cabinetValidation ||
    remarksValidation;

  useEffect(() => {
    getAvmInformation().then(
      (data) => {
        setAvmData(data);
      },
      () => {
        setSnackBarOption({
          status: "error",
          content:
            "自販機情報の読み込みに失敗しました。画面を再読み込みしてください。",
        });
        setShowSnackBar(true);
      }
    );
  }, [loadAvmInfoFlag]);

  const calcCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const hour = `0${today.getHours()}`.slice(-2);
    const minute = `0${today.getMinutes()}`.slice(-2);
    const currentDate = `${year}年 ${month}/${day} ${hour}:${minute}`;

    return currentDate;
  };

  const onLoadMapFunction = async () => {
    const geolocateButton = document.querySelector(
      ".mapboxgl-ctrl-geolocate"
    ) as HTMLButtonElement;
    geolocateButton.click();
    setLoadAvmInfoFlag(!loadAvmInfoFlag);
  };
  const onClickMapFunction = (e: MapLayerMouseEvent) => {
    if (isAdding) {
      setAddingMarker(e.lngLat);
    }
  };
  const onClickAddButtonFunction = () => {
    setIsAdding(true);
    setAvmInfo(null);
  };
  const onClickAddCancelButton = () => {
    setIsAdding(false);
    setAddingMarker(undefined);
  };
  const onClickRegisterThisPointButton = () => {
    setShowBackLayer(true);
    setShowRegiserThisPointModal(true);
  };
  const onClickEditButton = () => {
    const rareDrinkListKeys = Object.keys(RARE_DRINK_LIST);
    const rareDrinkInfo = avmInfo.rareDrink;
    for (const key of rareDrinkListKeys) {
      const rareDrinkInfoValue: string[] = rareDrinkInfo[key] || [];
      setRareDrink((current) => {
        const contemporaly = current;
        contemporaly[key] = rareDrinkInfoValue;
        return contemporaly;
      });
    }
    setPlaceOfAvm(avmInfo.placeOfAvm);
    setManagementNumber(avmInfo.managementNumber);
    setCabinet(avmInfo.cabinet);
    setRemarks(avmInfo.remarks);
    setShowDetail(false);
    setShowEditMode(true);
  };
  const onClickRegisterThisPointCancelButton = () => {
    setShowBackLayer(false);
    setShowRegiserThisPointModal(false);
  };
  const onClickRegisterThisPointOKButton = () => {
    setShowBackLayer(false);
    setShowRegiserThisPointModal(false);
    setShowRegisterMode(true);
  };
  const onClickCloseRegisterButton = () => {
    setShowBackLayer(true);
    setShowRegisterCancelConfirmModal(true);
  };
  const onClickCloseEditButton = () => {
    setShowBackLayer(true);
    setShowEditCancelConfirmModal(true);
  };
  const onClickCloseDetailButton = () => {
    setShowDetail(false);
    setAvmInfo(null);
    setShowBackLayer(false);
    setPlaceOfAvm("");
    setManagementNumber("");
    setCabinet("");
    setRemarks("");
    setDisplayDetail([]);
  };
  const onClickCloseRegisterCancelButton = () => {
    setShowBackLayer(false);
    setShowRegisterCancelConfirmModal(false);
  };
  const onClickCloseRegisterOKButton = () => {
    setShowBackLayer(false);
    setShowRegisterCancelConfirmModal(false);
    setPlaceOfAvm("");
    setManagementNumber("");
    setShowRegisterMode(false);
    setIsAdding(false);
    setAddingMarker(undefined);
    setCabinet("");
    setDisplayDetail([]);
    resetDrink();
    setRemarks("");
  };
  const onClickCloseEditCancelButton = () => {
    setShowBackLayer(false);
    setShowEditCancelConfirmModal(false);
  };
  const onClickCloseEditOKButton = () => {
    setShowBackLayer(false);
    setShowEditCancelConfirmModal(false);
    setPlaceOfAvm("");
    setManagementNumber("");
    setShowEditMode(false);
    setIsAdding(false);
    setAddingMarker(undefined);
    setCabinet("");
    setDisplayDetail([]);
    resetDrink();
    setRemarks("");
  };
  const onClickRegisterButton = () => {
    setShowBackLayer(true);
    setShowRegisterConfirmModal(true);
  };
  const onClickUpdateButton = () => {
    setShowBackLayer(true);
    setShowUpdateConfirmModal(true);
  };
  const onClickRegisterCancelButton = () => {
    setShowBackLayer(false);
    setShowRegisterConfirmModal(false);
  };
  const onClickUpdateCancelButton = () => {
    setShowBackLayer(false);
    setShowUpdateConfirmModal(false);
  };
  const onClickRegisterOKButton = () => {
    setShowRegisterConfirmModal(false);

    const registerDate = calcCurrentDate();

    let pictureName = "";
    if (picture) {
      pictureName = picture.name;
      const pictureExtention = pictureName.substring(
        pictureName.lastIndexOf(".")
      );
      const newPictureName = `${uuidv4()}${pictureExtention}`;
      pictureName = newPictureName;
      setShowRegisterConfirmModal(false);
      putItem(pictureName, picture);
    }
    putAvmInformation({
      registerDate: registerDate,
      lastModifiedDate: registerDate,
      coordinate: {
        lng: addingMarker!.lng,
        lat: addingMarker!.lat,
      },
      placeOfAvm: placeOfAvm,
      cabinet: cabinet,
      managementNumber: managementNumber,
      rareDrink: rareDrink,
      remarks: remarks,
      pictureName: picture ? pictureName : "",
      pictureDate: picture ? calcPictureDate(picture) : "",
    }).then(
      () => {
        setShowBackLayer(false);
        setPlaceOfAvm("");
        setManagementNumber("");
        setShowRegisterMode(false);
        setIsAdding(false);
        setAddingMarker(undefined);
        setLoadAvmInfoFlag(!loadAvmInfoFlag);
        setPicture(null);
        setCabinet("");
        setRemarks("");
        resetDrink();
        setDisplayDetail([]);
        setSnackBarOption({
          status: "success",
          content: "登録完了しました。",
        });
        setShowSnackBar(true);
        setAvmData(null);
      },
      () => {
        setShowBackLayer(false);
        setSnackBarOption({
          status: "error",
          content: "登録に失敗しました。もう一度お試しください",
        });
        setShowSnackBar(true);
      }
    );
  };
  const onClickUpdateOKButton = () => {
    setShowUpdateConfirmModal(false);
    const currentDate = calcCurrentDate();

    let pictureName = "";
    if (picture) {
      pictureName = picture.name;
      const pictureExtention = pictureName.substring(
        pictureName.lastIndexOf(".")
      );
      const newPictureName = `${uuidv4()}${pictureExtention}`;
      pictureName = newPictureName;
      setShowRegisterConfirmModal(false);
      putItem(pictureName, picture);
    }
    updateAvmInformation({
      id: avmInfo.id,
      registerDate: avmInfo.registerDate,
      lastModifiedDate: currentDate,
      coordinate: avmInfo.coordinate,
      placeOfAvm: placeOfAvm,
      cabinet: cabinet,
      managementNumber: managementNumber,
      rareDrink: rareDrink,
      remarks: remarks,
      pictureName: picture ? pictureName : avmInfo.pictureName,
      pictureDate: picture ? calcPictureDate(picture) : avmInfo.pictureDate,
    }).then(
      () => {
        setShowBackLayer(false);
        setPlaceOfAvm("");
        setManagementNumber("");
        setShowEditMode(false);
        setLoadAvmInfoFlag(!loadAvmInfoFlag);
        setPicture(null);
        setCabinet("");
        setRemarks("");
        resetDrink();
        setAvmInfo(null);
        setDisplayDetail([]);
        setSnackBarOption({
          status: "success",
          content: "編集完了しました。",
        });
        setShowSnackBar(true);
        setAvmData(null);
      },
      () => {
        setShowBackLayer(false);
        setSnackBarOption({
          status: "error",
          content: "編集に失敗しました。もう一度お試しください。",
        });
        setShowSnackBar(true);
      }
    );
  };
  const onClickPatrolButton = () => {
    setShowBackLayer(true);

    const currentDate = calcCurrentDate();
    updateAvmInformation({
      id: avmInfo.id,
      registerDate: avmInfo.registerDate,
      lastModifiedDate: currentDate,
      coordinate: avmInfo.coordinate,
      placeOfAvm: avmInfo.placeOfAvm,
      cabinet: avmInfo.cabinet,
      managementNumber: avmInfo.managementNumber,
      rareDrink: avmInfo.rareDrink,
      remarks: avmInfo.remarks,
      pictureName: avmInfo.pictureName,
      pictureDate: avmInfo.pictureDate,
    }).then(
      () => {
        setShowBackLayer(false);
        onClickCloseDetailButton();
        setSnackBarOption({
          status: "success",
          content: "巡回完了しました。",
        });
        setShowSnackBar(true);
        setLoadAvmInfoFlag(!loadAvmInfoFlag);
      },
      () => {
        setShowBackLayer(false);
        setSnackBarOption({
          status: "error",
          content: "巡回に失敗しました。もう一度お試しください",
        });
        setShowSnackBar(true);
      }
    );
  };
  const onChangeInputPicture = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setPicture(file);
    } else {
      setPicture(null);
    }
  };
  const onClickDisplayDetail = () => {
    setShowDetail(true);
  };

  const calcPictureDate = (picture: File) => {
    const lastModified = new Date(picture.lastModified);
    const date = lastModified.toLocaleDateString().replace("/", "年 ");
    const time = lastModified.toLocaleTimeString().slice(0, 5);

    return `${date} ${time}`;
  };
  const resetDrink = () => {
    setRareDrink(
      Object.keys(RARE_DRINK_LIST).reduce(
        (obj, key) => Object.assign(obj, { [key]: [] }),
        {}
      )
    );
  };

  return (
    <>
      {showSnackBar && (
        <SnackBar {...snackBarOption} setShowSnackBar={setShowSnackBar} />
      )}
      <Map
        mapboxAccessToken="pk.eyJ1IjoicmFpY2hhbmciLCJhIjoiY2xsejU0MGI5MHh1MzNxczZ4c3h2ZDU1dSJ9.0iyMfv1J7554dUmI6H8-IQ"
        projection={{ name: "mercator" }}
        initialViewState={{
          longitude: 139.64645623293865,
          latitude: 35.55417053084059,
          zoom: 14,
        }}
        minZoom={4}
        maxPitch={10}
        touchPitch={false}
        style={{ width: "100%", height: `calc(100% - ${globalHeaderHeight})` }}
        mapStyle="mapbox://styles/raichang/clm3gbvo700gl01rc88jd4cn3"
        onLoad={useCallback(() => {
          onLoadMapFunction();
        }, [])}
        onClick={onClickMapFunction}
        attributionControl={false}
      >
        {isAdding && (
          <div
            className={`absolute left-1/2 -translate-x-1/2 top-4 w-[90%] max-w-[500px] min-w-[300px] flex rounded border-2 p-3 border-blue-700 bg-blue-50`}
          >
            <div className="flex grow">
              {!addingMarker
                ? "自販機を登録する位置をタップ（クリック）してください。"
                : "この位置でよければ「この地点で登録」を押してください。"}
            </div>
          </div>
        )}
        {isAdding && (
          <div className="absolute w-full bottom-8 flex justify-center">
            <button
              className="w-2/5 max-w-[196px] h-12 bg-slate-200 rounded-full border-slate-300 border-2 mx-2 text-sm"
              onClick={onClickAddCancelButton}
            >
              キャンセル
            </button>
            <button
              className="w-2/5 max-w-[196px] h-12 bg-coke-red rounded-full border-rose-300 border-2 text-white disabled:bg-red-400 mx-2 text-sm"
              onClick={onClickRegisterThisPointButton}
              disabled={!addingMarker}
            >
              この地点で登録
            </button>
          </div>
        )}
        <NavigationControl
          showCompass={true}
          showZoom={false}
          style={{ margin: "6rem 0.5rem 0 0" }}
        />
        <GeolocateControl
          fitBoundsOptions={{}}
          trackUserLocation={true}
          showAccuracyCircle={false}
          showUserHeading={true}
          style={{ margin: "0.5rem 0.5rem 0 0" }}
        />
        {addingMarker && (
          <Marker
            longitude={addingMarker.lng}
            latitude={addingMarker.lat}
          ></Marker>
        )}
        {avmData &&
          avmData.map((obj: any) => {
            const lng = obj.coordinate.lng;
            const lat = obj.coordinate.lat;
            const color = getMarkerColor(obj.cabinet);
            return (
              <Marker
                longitude={lng}
                latitude={lat}
                color={color}
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setAvmInfo(obj);
                }}
              />
            );
          })}
        {avmInfo && (
          <Popup
            longitude={avmInfo.coordinate.lng}
            latitude={avmInfo.coordinate.lat}
            onClose={() => setAvmInfo(null)}
            offset={40}
            className="[&.mapboxgl-popup-close-button]:pr-2"
          >
            <div className="p-2">
              <p className="font-bold text-lg">{avmInfo.placeOfAvm}</p>
              <p className="text-sm">
                管理番号:{" "}
                {avmInfo.managementNumber !== ""
                  ? avmInfo.managementNumber
                  : "未登録"}
              </p>
              <p className="text-sm">最終更新日： {avmInfo.lastModifiedDate}</p>
              <button
                className={`px-8 h-8 w-full rounded-full bg-coke-red text-white mt-2`}
                onClick={() => onClickDisplayDetail()}
              >
                詳細を見る
              </button>
            </div>
          </Popup>
        )}
        <AddButton
          position="absolute left-1/2 bottom-8 -translate-x-[50%] border-2 border-rose-300"
          onClick={onClickAddButtonFunction}
          hidden={isAdding}
        />

        {/* 登録画面 */}
        {showRegisterMode && (
          <div className="absolute bg-white w-full h-full z-10 overflow-y-scroll">
            <button
              className="fixed right-2 top-14 w-8 h-8 text-slate-500"
              onClick={onClickCloseRegisterButton}
            >
              <XMarkIcon />
            </button>
            <div className="mx-auto w-4/5 max-w-[500px] mt-2">
              <div className="mx-auto py-2 border-b-2 my-2">
                <p className="text-center text-sm">自販機登録</p>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機の場所</h2>
                  <div className="bg-red-200 text-red-500 font-bold text-[0.5rem] h-4 leading-4 px-1 text-center mx-1 rounded-sm">
                    必須
                  </div>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${
                        placeOfAvmValidation ? "text-red-600" : ""
                      }`}
                    >
                      {placeOfAvm.length}
                    </span>
                    /60
                  </div>
                </div>
                <textarea
                  className="block w-full rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="例）ルフロン川崎 B1F ヨドバシ前"
                  value={placeOfAvm}
                  onChange={(e) => setPlaceOfAvm(e.target.value)}
                />
                {placeOfAvmValidation && (
                  <div className="text-red-600">
                    0文字以上60文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">筐体</h2>
                  <div className="bg-red-200 text-red-500 font-bold text-[0.5rem] h-4 leading-4 px-1 text-center mx-1 rounded-sm">
                    必須
                  </div>
                </div>
                {CABINET.map((obj, i) => {
                  return (
                    <div className="text-base ml-2 mb-1">
                      <input
                        type="radio"
                        id={`cabinet-${i}`}
                        name="cabinet"
                        key={i}
                        value={obj.value}
                        onChange={(e) => setCabinet(e.target.value)}
                      />
                      <label htmlFor={`cabinet-${i}`}>{obj.label}</label>
                    </div>
                  );
                })}
                {cabinetValidation && (
                  <div className="text-red-600">上から選択してください。</div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">管理番号</h2>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${
                        managementNumberValidation ? "text-red-600" : ""
                      }`}
                    >
                      {managementNumber.length}
                    </span>
                    /10
                  </div>
                </div>
                <input
                  type="number"
                  className="block w-full rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="10桁の番号 ハイフン不要"
                  value={managementNumber}
                  onChange={(e) => setManagementNumber(e.target.value)}
                />
                {managementNumberValidation && (
                  <div className="text-red-600">
                    10文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">レアドリンク</h2>
                </div>
                <div className="border-b border-slate-500">
                  {Object.keys(RARE_DRINK_LIST).map((key, i) => {
                    const label = RARE_DRINK_LIST[key].label;
                    const drinkList = RARE_DRINK_LIST[key].drinkList;
                    return (
                      <>
                        <div
                          className="relative text-lg py-2 pl-2 border-t border-slate-500 font-medium cursor-pointer list-none align-middle"
                          onClick={() => {
                            if (displayDetail.includes(i)) {
                              setDisplayDetail(
                                displayDetail.filter((num) => num !== i)
                              );
                            } else {
                              setDisplayDetail([...displayDetail, i]);
                            }
                          }}
                        >
                          {label}
                          <div className="absolute right-0 m-2 top-0 w-6 h-6 text-right text-slate-500">
                            {displayDetail.includes(i) ? (
                              <FaChevronDown size={24} />
                            ) : (
                              <FaChevronUp size={24} />
                            )}
                          </div>
                        </div>
                        {displayDetail.includes(i) && (
                          <div className="py-2 border-t border-slate-500 bg-slate-50">
                            {drinkList.map((item, j) => {
                              return (
                                <div className="">
                                  <label
                                    className="text-base block py-1"
                                    htmlFor={item.value}
                                    key={j}
                                  >
                                    <input
                                      id={item.value}
                                      type="checkbox"
                                      value={item.value}
                                      onChange={(e) => {
                                        if (
                                          rareDrink[key].includes(
                                            e.target.value
                                          )
                                        ) {
                                          const newRareDrink = rareDrink;
                                          newRareDrink[key].filter(
                                            (value) => value !== e.target.value
                                          );
                                          setRareDrink(newRareDrink);
                                        } else {
                                          const newRareDrink = rareDrink;
                                          newRareDrink[key].push(
                                            e.target.value
                                          );
                                        }
                                      }}
                                      className="mx-2 cursor-pointer"
                                    />
                                    {item.label}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">備考</h2>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${remarksValidation ? "text-red-600" : ""}`}
                    >
                      {remarks.length}
                    </span>
                    /150
                  </div>
                </div>
                <textarea
                  className="block w-full h-20 rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="例）カナダドライ ジンジャーエールの缶がある"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {remarksValidation && (
                  <div className="text-red-600">
                    150文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機画像</h2>
                </div>
                {picture && (
                  <div className="mt-2">
                    <p className="text-sm mb-2">プレビュー</p>
                    <img
                      className="mb-2 h-[300px]"
                      src={URL.createObjectURL(picture)}
                    />
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={onChangeInputPicture}
                />
              </div>
              <div className="flex mx-auto w-4/5 max-w-[500px] mt-2 justify-center">
                <button
                  className="w-3/5 h-12 w-48 bg-coke-red rounded-full border-rose-300 border-2 text-white my-1 text-sm disabled:bg-red-400"
                  onClick={onClickRegisterButton}
                  disabled={disableRegisterButtonFlag}
                >
                  登録する
                </button>
              </div>
            </div>
          </div>
        )}
        {/* 詳細画面 */}
        {showDetail && (
          <div className="absolute bg-white w-full h-full z-10 overflow-y-scroll">
            <button
              className="fixed right-2 top-14 w-8 h-8 text-slate-500"
              onClick={onClickCloseDetailButton}
            >
              <XMarkIcon />
            </button>
            <div className="mx-auto w-4/5 max-w-[500px] mt-2">
              <div className="mx-auto py-2 border-b-2 my-2">
                <p className="text-center text-sm">自販機詳細</p>
              </div>
              <div>最終更新日：{avmInfo.lastModifiedDate}</div>
              <div className="flex justify-between px-2 my-4">
                <button
                  className="w-full max-w-[196px] h-12 bg-amber-300 rounded-full border-amber-200 border-2 mx-2 text-sm"
                  onClick={onClickPatrolButton}
                >
                  巡回
                </button>
                <button
                  className="w-full max-w-[196px] h-12 bg-coke-red rounded-full border-rose-300 border-2 text-white disabled:bg-red-400 mx-2 text-sm"
                  onClick={onClickEditButton}
                >
                  編集
                </button>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機の場所</h2>
                </div>
                <p className="text-sm">{avmInfo.placeOfAvm}</p>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機画像</h2>
                </div>
                {avmInfo.pictureName.length === 0 ? (
                  <p className="text-sm">未登録</p>
                ) : (
                  <>
                    <img
                      src={`https://img.co-avm-share.net/${avmInfo.pictureName}`}
                      className="w-full"
                    />
                    <p className="text-sm text-right">
                      {avmInfo.pictureDate}時点
                    </p>
                  </>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">筐体</h2>
                </div>
                <p className="text-sm">
                  {avmInfo.cabinet
                    ? CABINET.find((obj) => obj.value === avmInfo.cabinet)!
                        .label
                    : "未登録"}
                </p>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">管理番号</h2>
                </div>
                <p className="text-sm">
                  {avmInfo.managementNumber === ""
                    ? "未登録"
                    : avmInfo.managementNumber}
                </p>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">レアドリンク</h2>
                </div>
                {Object.values(avmInfo.rareDrink).every(
                  (array: any) => array.length === 0
                ) ? (
                  <p className="text-sm">レアドリンクなし</p>
                ) : (
                  <div className="border-b border-slate-500">
                    {Object.values(RARE_DRINK_LIST).map((obj, i) => {
                      const drinkGenre = Object.keys(RARE_DRINK_LIST)[i];
                      const label = obj.label;
                      const drinkList = obj.drinkList;
                      const checkedDrinkList =
                        avmInfo.rareDrink[drinkGenre] || [];
                      return checkedDrinkList.length === 0 ? null : (
                        <>
                          <div
                            className="relative text-lg py-2 pl-2 border-t border-slate-500 font-medium cursor-pointer list-none align-middle"
                            onClick={() => {
                              if (displayDetail.includes(i)) {
                                setDisplayDetail(
                                  displayDetail.filter((num) => num !== i)
                                );
                              } else {
                                setDisplayDetail([...displayDetail, i]);
                              }
                            }}
                          >
                            {label}
                            <div className="absolute right-0 m-2 top-0 w-6 h-6 text-right text-slate-500">
                              {displayDetail.includes(i) ? (
                                <FaChevronDown size={24} />
                              ) : (
                                <FaChevronUp size={24} />
                              )}
                            </div>
                          </div>
                          {displayDetail.includes(i) && (
                            <div className="py-2 border-t border-slate-500 bg-slate-50">
                              <ul className="list-disc text-sm ml-6">
                                {drinkList.map((drink, j) => {
                                  return checkedDrinkList.includes(
                                    drink.value
                                  ) ? (
                                    <li key={j}>{drink.label}</li>
                                  ) : null;
                                })}
                              </ul>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">備考</h2>
                </div>
                <p className="text-sm">
                  {avmInfo.remarks === "" ? "未記入" : avmInfo.remarks}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* 編集画面 */}
        {showEditMode && (
          <div className="absolute bg-white w-full h-full z-10 overflow-y-scroll">
            <button
              className="fixed right-2 top-14 w-8 h-8 text-slate-500"
              onClick={onClickCloseEditButton}
            >
              <XMarkIcon />
            </button>
            <div className="mx-auto w-4/5 max-w-[500px] mt-2">
              <div className="mx-auto py-2 border-b-2 my-2">
                <p className="text-center text-sm">自販機編集</p>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機の場所</h2>
                  <div className="bg-red-200 text-red-500 font-bold text-[0.5rem] h-4 leading-4 px-1 text-center mx-1 rounded-sm">
                    必須
                  </div>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${
                        placeOfAvmValidation ? "text-red-600" : ""
                      }`}
                    >
                      {placeOfAvm.length}
                    </span>
                    /60
                  </div>
                </div>
                <textarea
                  className="block w-full rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="例）ルフロン川崎 B1F ヨドバシ前"
                  value={placeOfAvm}
                  onChange={(e) => setPlaceOfAvm(e.target.value)}
                />
                {placeOfAvmValidation && (
                  <div className="text-red-600">
                    0文字以上60文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">筐体</h2>
                  <div className="bg-red-200 text-red-500 font-bold text-[0.5rem] h-4 leading-4 px-1 text-center mx-1 rounded-sm">
                    必須
                  </div>
                </div>
                {CABINET.map((obj, i) => {
                  return (
                    <div className="text-base ml-2 mb-1">
                      <input
                        type="radio"
                        id={`cabinet-${i}`}
                        name="cabinet"
                        key={i}
                        value={obj.value}
                        onChange={(e) => setCabinet(e.target.value)}
                        defaultChecked={obj.value === avmInfo.cabinet}
                      />
                      <label htmlFor={`cabinet-${i}`}>{obj.label}</label>
                    </div>
                  );
                })}
                {cabinetValidation && (
                  <div className="text-red-600">上から選択してください。</div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">管理番号</h2>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${
                        managementNumberValidation ? "text-red-600" : ""
                      }`}
                    >
                      {managementNumber.length}
                    </span>
                    /10
                  </div>
                </div>
                <input
                  type="number"
                  className="block w-full rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="10桁の番号 ハイフン不要"
                  value={managementNumber}
                  onChange={(e) => setManagementNumber(e.target.value)}
                />
                {managementNumberValidation && (
                  <div className="text-red-600">
                    10文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">レアドリンク</h2>
                </div>
                <div className="border-b border-slate-500">
                  {Object.keys(RARE_DRINK_LIST).map((key, i) => {
                    const label = RARE_DRINK_LIST[key].label;
                    const drinkList = RARE_DRINK_LIST[key].drinkList;
                    return (
                      <>
                        <div
                          className="relative text-lg py-2 pl-2 border-t border-slate-500 font-medium cursor-pointer list-none"
                          onClick={() => {
                            if (displayDetail.includes(i)) {
                              setDisplayDetail(
                                displayDetail.filter((num) => num !== i)
                              );
                            } else {
                              setDisplayDetail([...displayDetail, i]);
                            }
                          }}
                        >
                          {label}
                          <div className="absolute right-0 m-2 top-0 w-6 h-6 text-right text-slate-500">
                            {displayDetail.includes(i) ? (
                              <FaChevronDown size={24} />
                            ) : (
                              <FaChevronUp size={24} />
                            )}
                          </div>
                        </div>
                        {displayDetail.includes(i) && (
                          <div className="py-2 border-t border-slate-500 bg-slate-50">
                            {drinkList.map((item, j) => {
                              return (
                                <div className="">
                                  <label
                                    className="text-base block py-1"
                                    htmlFor={item.value}
                                    key={j}
                                  >
                                    <input
                                      id={item.value}
                                      type="checkbox"
                                      value={item.value}
                                      onChange={(e) => {
                                        if (
                                          rareDrink[key].includes(
                                            e.target.value
                                          )
                                        ) {
                                          const newRareDrink = rareDrink;
                                          newRareDrink[key].filter(
                                            (value) => value !== e.target.value
                                          );
                                          setRareDrink(newRareDrink);
                                        } else {
                                          const newRareDrink = rareDrink;
                                          newRareDrink[key].push(
                                            e.target.value
                                          );
                                        }
                                      }}
                                      defaultChecked={rareDrink[key].includes(
                                        item.value
                                      )}
                                      className="mx-2 cursor-pointer"
                                    />
                                    {item.label}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">備考</h2>
                  <div className="text-gray-500 grow text-right">
                    <span
                      className={`${remarksValidation ? "text-red-600" : ""}`}
                    >
                      {remarks.length}
                    </span>
                    /150
                  </div>
                </div>
                <textarea
                  className="block w-full h-20 rounded-md border py-1.5 pl-2 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  placeholder="例）カナダドライ ジンジャーエールの缶がある"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {remarksValidation && (
                  <div className="text-red-600">
                    150文字以下で入力してください。
                  </div>
                )}
              </div>
              <div className="px-2 my-4">
                <div className="flex items-center">
                  <h2 className="font-semibold">自販機画像の変更</h2>
                </div>
                {picture && (
                  <div className="mt-2">
                    <p className="text-sm mb-2">プレビュー</p>
                    <img
                      className="mb-2 h-[300px]"
                      src={URL.createObjectURL(picture)}
                    />
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={onChangeInputPicture}
                />
              </div>
              <div className="flex mx-auto w-4/5 max-w-[500px] mt-2 justify-center">
                <button
                  className="w-3/5 h-12 w-48 bg-coke-red rounded-full border-rose-300 border-2 text-white my-1 text-sm disabled:bg-red-400"
                  onClick={onClickUpdateButton}
                  disabled={disableRegisterButtonFlag}
                >
                  更新する
                </button>
              </div>
            </div>
          </div>
        )}
        {showBackLayer && (
          <div className="relative w-full h-full ">
            <div className="relative bg-black/50 w-full h-full z-10">
              {showRegiserThisPointModal && (
                <Modal
                  titleCaption="この位置で登録しますか？"
                  contentCaptionList={["登録後の位置変更はできません。"]}
                  onClickCancelButton={onClickRegisterThisPointCancelButton}
                  onClickOKButton={onClickRegisterThisPointOKButton}
                />
              )}
              {showRegisterCancelConfirmModal && (
                <Modal
                  titleCaption="登録を中断しますか？"
                  contentCaptionList={["内容はすべて破棄されます。"]}
                  onClickCancelButton={onClickCloseRegisterCancelButton}
                  onClickOKButton={onClickCloseRegisterOKButton}
                  cancelButtonCaption="続ける"
                  okButtonCaption="中断する"
                />
              )}
              {showEditCancelConfirmModal && (
                <Modal
                  titleCaption="編集を中断しますか？"
                  contentCaptionList={["変更はすべて破棄されます。"]}
                  onClickCancelButton={onClickCloseEditCancelButton}
                  onClickOKButton={onClickCloseEditOKButton}
                  cancelButtonCaption="続ける"
                  okButtonCaption="中断する"
                />
              )}
              {showRegisterConfirmModal && (
                <Modal
                  titleCaption="この内容で登録しますか？"
                  onClickCancelButton={onClickRegisterCancelButton}
                  onClickOKButton={onClickRegisterOKButton}
                />
              )}
              {showUpdateConfirmModal && (
                <Modal
                  titleCaption="この内容で更新しますか？"
                  onClickCancelButton={onClickUpdateCancelButton}
                  onClickOKButton={onClickUpdateOKButton}
                />
              )}
            </div>
          </div>
        )}
      </Map>
    </>
  );
};

export default BaseMap;
