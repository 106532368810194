import {Bars3Icon, QuestionMarkCircleIcon} from '@heroicons/react/24/outline'
import AddButton from '../container/AddButton'

interface prop{
    height: string
}

const GlobalHeader = (props: prop) => {
    const {height} = props

    return (
    <div className={`flex bg-coke-red w-full h-12 pl-3 pr-3 justify-between items-center`}>
        <button className='flex-none w-8 h-8 text-white'><Bars3Icon/></button>
        <div className="grow h-16 text-white text-center text-2xl py-4 font-medium">自販機シェア</div>
        <button className='flex-none w-8 h-8 align-middle text-white'><QuestionMarkCircleIcon/></button>
    </div>
    )
}

export default GlobalHeader