import BaseMap from './map/BaseMap';
import GlobalHeader from './header/GlobalHeader';

function App() {

  const globalHeaderHeight = '48px'

  return (
    <div className='flex-col h-full relative'>
    <GlobalHeader
      height={globalHeaderHeight}
    />
    <BaseMap
      globalHeaderHeight={globalHeaderHeight}
    />
    </div>
  )
}

export default App;
