import { MouseEventHandler, useState } from "react"

interface Prop{
    position: string
    onClick: MouseEventHandler
    hidden: boolean
}

const AddButton = (props: Prop) => {
    const {position, onClick, hidden} = props

    return hidden ? null : 
    <button 
        className={`px-8 w-60 h-12 rounded-full bg-coke-red text-white text-lg ${position}`}
        onClick={onClick}
    >＋ 自販機を登録する</button>
}

export default AddButton